import React from 'react';
import './home.component.css'; // Optional: Create a CSS file for styling if needed
import videoFile from '../assets/Landing-Page.mp4';
const Home = () => {
  return (
    <div className="home-container">
      <div className="video-container">
        
      </div>
      <div className="home-content">
        <h1>Welcome to My Portfolio!</h1>
        <p> </p>
      </div>
    </div>
  );
};

export default Home;
